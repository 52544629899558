import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO/seo';
import BankTemplate from '../../../components/banks/BankTemplate';
import banksData from '../../../components/banks/banksData';

const BunqPersonal = (page: PageProps) => {
  return (
    <Layout>
      <Seo page={page} title="bunq details" />
      <BankTemplate data={banksData.bunq} type="personal" />
    </Layout>
  );
};

export default BunqPersonal;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
